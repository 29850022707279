import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "@remix-run/react";
import { route } from "~/utils/route";
import classNames from "classnames";
import { useAppStore } from "~/store";
import { isSSR } from "~/utils/ssr";
import { cn } from "~/utils/cn";

interface InfoHeaderProps {
  children: React.ReactNode;
  className?: string;
  disableSticky?: boolean;
}

export default function InfoHeader({ children, className }: InfoHeaderProps) {
  const threadcasts = isSSR()
    ? []
    : useAppStore(store => store.discussion.threadcasts);

  const filteredThreadcasts =
    (threadcasts ?? [])?.filter(t => t.children >= 15) || [];

  return (
    <div
      className={cn(
        "flex backdrop-blur-sm sm:mt-0 bg-pri dark:bg-pri-d border-b border-pri dark:border-pri-d sticky top-0 z-[999] whitespace-pre-line",
        {
          "mt-[48px]":
            (!filteredThreadcasts || filteredThreadcasts.length === 0) &&
            !className?.includes("mt-0"),
          "mt-0": filteredThreadcasts.length > 0 || className?.includes("mt-0")
        },
        className
      )}
    >
      {children}
    </div>
  );
}

interface InfoHeaderLabelProps {
  children: React.ReactNode;
}

export function InfoHeaderLabel({ children }: InfoHeaderLabelProps) {
  return (
    <h1 className="h-12 flex items-center px-4 pl-2 text-pri dark:text-pri-d font-bold ">
      {children}
    </h1>
  );
}

export function BackButton({ defaultRoute }: { defaultRoute?: string }) {
  const navigate = useNavigate();

  return (
    <div className="h-12 px-3 flex items-center">
      <div
        className="h-8 w-8 bg-transparent hover:bg-gray-200 dark:hover:bg-zinc-700 rounded-full flex items-center justify-center cursor-pointer"
        onClick={() => {
          // if (route.previous === null) {
          //
          //   // if (defaultRoute) {
          //   //   return navigate(defaultRoute);
          //   // }
          //   navigate(-1);
          // } else {
          //   navigate(-1);
          // }
          // TODO - remove the 'defaultRoute' prop and use the commented code above if it works as expected
          navigate(-1);
        }}
      >
        <FontAwesomeIcon icon={faArrowLeftLong} fixedWidth />
      </div>
    </div>
  );
}
